import { url } from "inspector";
import React from "react";
import "./global.css";
function App() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: "url(./dark-foggy.jpg)",
        backgroundSize: "cover",
        position: "relative",
      }}
      className="App"
    >
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent:"center",
          alignContent:"center",
          flexWrap:"wrap",
          opacity:0.9
        }}
      >
        <img width={"300px"}  style={{alignSelf:"center", marginBottom:"40px"}}src="./logo.png" />
        <a style={{ fontSize: "150%" }}>Технические работы</a>
        <p style={{ fontSize: "90%" }}>
          Приносим извинения за неудобства
        </p>
      </div>
    </div>
  );
}

export default App;
